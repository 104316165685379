import { mapActions } from "vuex"
import { gameInfo } from "@/config/lhc.config";
// 系统模块
export default {
    namespaced: true,
    state() {
        return {
            currentGame: {
                code: 20001,//遊戲編碼
                name: "福彩3D",//遊戲名稱
                type: "futicai",//遊戲類型
                plays: gameInfo[0].plays,//遊戲玩法
                plat: '0'//游戏默认盘口 
            },
            navigation: "tema",
            liuhe: {
                status: 0,//彩种状态 0 1 2
            }
        }
    },
    mutations: {
        SET_CURRENT_PLAT_CODE(state, plat) {
            state.currentGame.plat = plat;
        },
        SET_CURRENT_GAME(state, game) {
            state.currentGame = game;
        },
        SET_NAVIGATION_NAME(state, navigation) {
            state.navigation = navigation
        }
    },
    actions: {
        setCurrentPlatCodeAction({ commit }, plat) {
            commit('SET_CURRENT_PLAT_CODE', plat)
        },
        switchCurrentGameAction({ commit }, game) {
            commit('SET_CURRENT_GAME', game)
        },
        setNavgationNameAction({ commit }, navigation) {
            commit('SET_NAVIGATION_NAME', navigation)
        }
    }
}
