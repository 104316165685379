// 游戏相关的API函數
import request from '@/utils/request'




/**
 * 註單明细
 * @param {Object} form - 参考接口文档
 */
export const requestLastBets = (form) => {
    return request('/game/bet/page', 'post', form)
}
/**
 * 投註汇總
 * @param {*} param0 
 * @returns 
 */
export const requestBetContentSummary = ({ termNumber = 1, betType = null, gameCode = 20001 }) => {
    return request('/game/bet/content-sum', 'get', { termNumber, betType, gameCode })
}

/**
 * 投註月报表
 * @param {Object} form - 参考接口文档
 */
export const requestBetMonthReport = (form) => {
    return request('/game/bet/month', 'post', form)
}




/**
 * 投註项 下拉數據集
 */
export const requestBetType = (data) => {
    return request('/game/bet/type/select', 'get', data)
}

/**
 * 期數下註情况
 */
export const requestGameInfo = (data) => {
    return request('/game/info', 'get', data)
}
/**
 * 游戏下註號碼
 */
export const requestGameBetNumber = () => {
    return request('/game/bet/numbers', 'get', {})
}

/**
 * 提交註單
 * @param {Object} form - 参考接口文档
 */
export const addBetOrder = (form) => {
    return request('/game/bet/add', 'post', form)
}
/**
 * 游戏賠率及限制
 */
export const requestGameOddAndFilter = (data) => {
    return request('/game/bet/odds-filter', 'post', data)
}
/**
 * 復式明细
 * @param {Object} form - 参考接口文档
 */
export const requestBetOddsDetail = (data) => {
    return request('/game/bet/odds-detail', 'get', data)
}
/**
 * 開獎結果
 */
export const requestGameSettleResult = (data) => {
    return request('/game/settle-result', 'get', data)
}

/**
 * 通知公告
 */
export const requestScrollNotice = () => {
    return request('/game/scroll-notice', 'get', {})
}

/**
 * 通知公告 分页
 */
export const requestPageNotice = (form) => {
    return request('/game/page-notice', 'post', form)
}
/**
 * 通知公告明細
 * @param {Object} form - 参考接口文档
 */
export const requestDetailNotice = (data) => {
    return request('/game/notice-detail', 'get', data)
}


/**
 * 长龙
 */
export const requestLongRes = (data) => {
    return request('/game/longRes', 'post', data)
}