import md5 from "md5"
import config from '@/config'
export const secret = process.env.NODE_ENV === 'development' ? config.secret.development : config.secret.production
import find from "lodash.find";
import isUndefined from "lodash.isundefined";
/**
 * 
 * @param {生成签名} jsonObj 
 * @returns 
 */
export function generateSign(jsonObj) {
    // console.log('000000 ParameterSignatureInterceptor jsonObj----->', jsonObj)
    if (Object.prototype.toString.call(jsonObj) == "[object Object]") {
        let noNullObject = [];
        for (let key in jsonObj) {
            // console.warn(key, 'jsonObj[key]', jsonObj[key], jsonObj[key] != "")
            // if (jsonObj[key] !== "") {
                noNullObject.push(new Array(key, jsonObj[key]));
            // }
        }
        // console.log("000000 noNullObject", noNullObject)
        let sortSignArray = noNullObject.sort(function (x, y) {
            return x[0].localeCompare(y[0])
        })
        let signArray = [];
        for (var j = 0; j < sortSignArray.length; j++) {
            if (Object.prototype.toString.call(sortSignArray[j][1]) == "[object Array]" || Object.prototype.toString.call(
                sortSignArray[j][1]) == "[object Object]") {
                signArray.push(sortSignArray[j][0] + '=' + JSON.stringify(sortSignArray[j][1]));
            } else {
                signArray.push(sortSignArray[j][0] + '=' + sortSignArray[j][1]);
            }
        }
        // console.log('111 ParameterSignatureInterceptor 生成签名拼接串----->', signArray.join('&'))
        return md5(signArray.join('&') + "&key=" + secret).toLowerCase();
    } else return "";
}
/**
 * 快速面板
 * @param {*} numbers 
 * @param {*} name 
 * @param {*} radio 
 * @returns 
 */
export function quickPanelBet(numbers, name, radio) {
    if (!name && numbers.length == 0) {
        return [];
    }
    let checked = find(numbers, (item) => {
        return item.checked == true;
    });
    if (!radio && isUndefined(checked)) {
        return;
    }
    switch (name) {
        case "單":
            numbers.forEach((items) => {
                if (Number(items.ball) % 2 != 0 && items.ball != "49") {
                    items.checked = radio;
                }
                return false;
            });
            break;
        case "双":
            numbers.forEach((items) => {
                if (Number(items.ball) % 2 == 0) {
                    items.checked = radio;
                }
                return false;
            });
            break;
        case "大":
            numbers.forEach((items) => {
                if (Number(items.ball) >= 25 && items.ball != "49") {
                    items.checked = radio;
                }
                return false;
            });
            break;
        case "小":
            numbers.forEach((items) => {
                if (Number(items.ball) <= 24) {
                    items.checked = radio;
                }
                return false;
            });
            break;
        case "大單":
            numbers.forEach((items) => {
                if (
                    Number(items.ball) >= 25 &&
                    Number(items.ball) % 2 != 0 &&
                    items.ball != "49"
                ) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "大双":
            numbers.forEach((items) => {
                if (Number(items.ball) >= 25 && Number(items.ball) % 2 == 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "小單":
            numbers.forEach((items) => {
                if (Number(items.ball) <= 24 && Number(items.ball) % 2 != 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "小双":
            numbers.forEach((items) => {
                if (Number(items.ball) <= 24 && Number(items.ball) % 2 == 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "合單":
            numbers.forEach((items) => {
                if (
                    (Number(items.ball.substring(0, 1)) +
                        Number(items.ball.substring(1, 2))) %
                    2 !=
                    0 &&
                    items.ball != "49"
                ) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "合双":
            numbers.forEach((items) => {
                if (
                    (Number(items.ball.substring(0, 1)) +
                        Number(items.ball.substring(1, 2))) %
                    2 ==
                    0
                ) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "合大":
            numbers.forEach((items) => {
                if (
                    Number(items.ball.substring(0, 1)) +
                    Number(items.ball.substring(1, 2)) >=
                    7 &&
                    items.ball != "49"
                ) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "合小":
            numbers.forEach((items) => {
                if (
                    Number(items.ball.substring(0, 1)) +
                    Number(items.ball.substring(1, 2)) <=
                    6 &&
                    items.ball != "49"
                ) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "尾大":
            numbers.forEach((items) => {
                if (Number(items.ball.substring(1, 2)) >= 5 && items.ball != "49") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "尾小":
            numbers.forEach((items) => {
                if (Number(items.ball.substring(1, 2)) <= 4 && items.ball != "49") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "家禽":
            numbers.forEach((items) => {
                if (
                    items.zodiac == "牛" ||
                    items.zodiac == "马" ||
                    items.zodiac == "羊" ||
                    items.zodiac == "鸡" ||
                    items.zodiac == "狗" ||
                    items.zodiac == "猪"
                ) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "野兽":
            numbers.forEach((items) => {
                if (
                    items.zodiac == "鼠" ||
                    items.zodiac == "虎" ||
                    items.zodiac == "兔" ||
                    items.zodiac == "龙" ||
                    items.zodiac == "蛇" ||
                    items.zodiac == "猴"
                ) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;

        case "紅波":
            numbers.forEach((items) => {
                if (items.color == "red") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "藍波":
            numbers.forEach((items) => {
                if (items.color == "blue") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "綠波":
            numbers.forEach((items) => {
                if (items.color == "green") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;

        case "紅單":
            numbers.forEach((items) => {
                if (items.color == "red" && Number(items.ball) % 2 != 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "紅双":
            numbers.forEach((items) => {
                if (items.color == "red" && Number(items.ball) % 2 == 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "紅大":
            numbers.forEach((items) => {
                if (items.color == "red" && Number(items.ball) >= 25) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "紅小":
            numbers.forEach((items) => {
                if (items.color == "red" && Number(items.ball) <= 24) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;

        case "藍單":
            numbers.forEach((items) => {
                if (items.color == "blue" && Number(items.ball) % 2 != 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "藍双":
            numbers.forEach((items) => {
                if (items.color == "blue" && Number(items.ball) % 2 == 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "藍大":
            numbers.forEach((items) => {
                if (items.color == "blue" && Number(items.ball) >= 25) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "藍小":
            numbers.forEach((items) => {
                if (items.color == "blue" && Number(items.ball) <= 24) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;

        case "綠單":
            numbers.forEach((items) => {
                if (items.color == "green" && Number(items.ball) % 2 != 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "綠双":
            numbers.forEach((items) => {
                if (items.color == "green" && Number(items.ball) % 2 == 0) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "綠大":
            numbers.forEach((items) => {
                if (items.color == "green" && Number(items.ball) >= 25) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "綠小":
            numbers.forEach((items) => {
                if (items.color == "green" && Number(items.ball) <= 24) {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        //生肖
        case "鼠":
            numbers.forEach((items) => {
                if (items.zodiac == "鼠") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "牛":
            numbers.forEach((items) => {
                if (items.zodiac == "牛") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "虎":
            numbers.forEach((items) => {
                if (items.zodiac == "虎") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "兔":
            numbers.forEach((items) => {
                if (items.zodiac == "兔") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "龙":
            numbers.forEach((items) => {
                if (items.zodiac == "龙") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "蛇":
            numbers.forEach((items) => {
                if (items.zodiac == "蛇") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "马":
            numbers.forEach((items) => {
                if (items.zodiac == "马") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "羊":
            numbers.forEach((items) => {
                if (items.zodiac == "羊") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "猴":
            numbers.forEach((items) => {
                if (items.zodiac == "猴") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "鸡":
            numbers.forEach((items) => {
                if (items.zodiac == "鸡") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "狗":
            numbers.forEach((items) => {
                if (items.zodiac == "狗") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
        case "猪":
            numbers.forEach((items) => {
                if (items.zodiac == "猪") {
                    items.checked = radio;
                } else {
                    return false;
                }
            });
            break;
    }
    return numbers;
}
/**
 * 组合数字 
 * @param {["02","15","16","25"]} numbers  待组合球号
 * @param {[]} empty 空数组
 * @returns Array
 */
export function constituteResult(numbers, empty) {
    if (numbers.length == 0) return empty;
    for (let i = 0; i < numbers.length; i++) {
        let subnumbers = numbers.slice(i + 1, 999);
        for (let j = 0; j < subnumbers.length; j++) {
            if (numbers[i] == subnumbers[j]) {
                continue;
            }
            empty.push(numbers[i] + subnumbers[j]);
        }
    }
    return empty;
};

/**
 * 
 * @param {*} nums 
 * @param {*} d 
 * @param {*} targetLength 
 * @param {*} curIdx 
 * @param {*} curr 
 * @param {*} ans 
 * @returns 
 */
export function combination(nums, d, targetLength, curIdx, curr, ans) {
    if (d === targetLength) {
        ans.push([...curr]);
        return;
    }
    for (let i = curIdx; i < nums.length; i++) {
        curr.push(nums[i]);
        combination(nums, d + 1, targetLength, i + 1, curr, ans);
        curr.pop();
    }
}
/**
 * 
 * @param {*} arr 
 * @returns 
 */
export function cartesian(arr) {
    if (arr.length < 2) return arr[0] || [];
    return [].reduce.call(arr, function (col, set) {
        let res = [];
        col.forEach(c => {
            set.forEach(s => {
                let t = [].concat(Array.isArray(c) ? c : [c]);
                t.push(s);
                res.push(t);
            })
        });
        return res;
    });
}
/**
 * 秒转时分秒
 * @param {*} seconds 
 * @returns 
 */
export function formatSeconds(seconds) {
    if (!seconds || seconds <= 0) return "";
    let result = parseInt(seconds)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
    return `${h}時${m}分${s}秒`;
}
/**
 * 是否連續
 * @param {*} array 
 * @returns 
 */
export function isContinuation(array) {
    let i = array[0];
    let isContinuation = true;
    for (let e in array) {
        if (array[e] != i) {
            isContinuation = false;
            break;
        }
        i++;
    }
    return isContinuation;
}

export function isJsGame(gameCode) {
    return  gameCode=='20003'
}